import { useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from '@mui/material';

import MaterialTable from '@material-table/core';

import PageTitle from '@components/PageTitle';

import { formatDate } from '../../helpers/dateFormat';
import { useAddBill, useReadBills } from '../../hooks/bill';
import { useReadCompanies } from '../../hooks/company';
import { useFilterHireChart } from '../../hooks/hirechart';

const SearchBy = [
  {
    label: 'Sub Agent',
    value: 'subagent',
  },
  {
    label: 'Client Name',
    value: 'client',
  },
  {
    label: 'Owner Code',
    value: 'owncode',
  },
  {
    label: 'Veh. No.',
    value: 'vno',
  },
];

const PendingBills = () => {
  const navigate = useNavigate();

  const [selectedHirecharts, setSelectedHirecharts] = useState<any>([]);
  const { data: billList, isLoading } = useReadBills();

  const { mutate: filterHireChartMutate, isPending } = useFilterHireChart();

  const [hirecharts, setHirecharts] = useState<any>([]);
  const [srlocalhirechart, setSRLocalHirechart] = useState<any[]>([]);
  const { data: companiesData } = useReadCompanies();
  const [companies, setCompanies] = useState<any>([]);

  useEffect(() => {
    document.title = 'Bill - nunes';
    handleSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (hirecharts) {
      const hirechartsWithSr = hirecharts.map(
        (hireItems: any, index: number) => ({
          ...hireItems,
          sr: index + 1,
        })
      );
      setSRLocalHirechart(hirechartsWithSr);
    }
  }, [hirecharts]);

  useEffect(() => {
    if (companiesData && companiesData.length) {
      setCompanies(companiesData);
    }
  }, [companiesData]);

  const { enqueueSnackbar } = useSnackbar();

  // const handleDetails = (rowData: any) => {
  //   if (rowData) {
  //     navigate(`/newBill/${rowData.billno}`);
  //   }
  // };
  const { mutate: useAddBillMutate } = useAddBill();

  const setSelectedRows = (rowsData: any) => {
    setSelectedHirecharts(rowsData);
  };

  const getDefaultDateRange = () => {
    const today = new Date();

    const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 2);

    const lastDayOfMonth = new Date(
      today.getFullYear(),
      today.getMonth() + 1,
      1
    );

    return {
      startDate: firstDayOfMonth.toISOString().split('T')[0],
      endDate: lastDayOfMonth.toISOString().split('T')[0],
    };
  };

  const savedDateRange = (() => {
    const savedData = localStorage.getItem('NewBillFilteredDateRange');
    return savedData ? JSON.parse(savedData) : getDefaultDateRange();
  })();

  const { handleSubmit, control, watch } = useForm({
    mode: 'onChange',
    defaultValues: {
      startDate: savedDateRange.startDate,
      endDate: savedDateRange.endDate,
      company: '',
      hirechartType: [],
      searchBy: '',
      searchValue: '',
    },
  });

  const watchedStartDate = watch('startDate');
  const watchedEndDate = watch('endDate');

  useEffect(() => {
    localStorage.setItem(
      'NewBillFilteredDateRange',
      JSON.stringify({
        startDate: watchedStartDate,
        endDate: watchedEndDate,
      })
    );
  }, [watchedStartDate, watchedEndDate]);

  const handleSearch = handleSubmit((formData: any) => {
    const filterCriteria = {
      ...(formData?.startDate && { startDate: formData?.startDate }),
      ...(formData?.endDate && { endDate: formData?.endDate }),
      ...(formData?.company && { company: formData?.company }),
    };
    if (formData?.hirechartType && formData.hirechartType.length > 0) {
      filterCriteria.hirechartType = formData.hirechartType;
    }

    if (formData.searchBy && formData.searchValue) {
      filterCriteria[formData.searchBy] = formData.searchValue ?? '';
    }

    filterHireChartMutate(filterCriteria, {
      onSuccess: (filteredData: any) => {
        if (filteredData?.data) {
          const processedData = filteredData?.data.filter(
            (h: any) => h.client !== 'EX'
          );
          // processedData = processedData.map((item: any) => ({
          //   ...item,
          // }));
          const onlyUnbilledData = processedData.filter(
            (item: any) => item?.bill !== '1'
          );
          setHirecharts(onlyUnbilledData);
        }
      },
      onError: () => {
        enqueueSnackbar('Failed to fetch filtered data', { variant: 'error' });
      },
    });
  });

  const handleMakeBill = () => {
    let hirechartToDisplay: any = [];
    hirechartToDisplay = selectedHirecharts.map((h: any) => {
      const bill: any = billList?.find(b => Number(b.hid) === Number(h.id));
      if (!bill) {
        // eslint-disable-next-line
        useAddBillMutate({
          hid: h.id,
          mode: 3,
        });
      }
      return hirecharts?.find((hc: any) => hc.id === h.id);
    });
    localStorage.setItem(
      'hirechartDetails',
      JSON.stringify(hirechartToDisplay)
    );
    navigate(`/newBill/details`);
  };

  const columns: any = [
    {
      title: 'S.No',
      field: 'sr',
      sorting: true,
      width: '5%',
    },
    {
      title: 'Date',
      field: 'date',
      defaultSort: 'desc',
      render: (rowData: any) => `${formatDate(rowData?.date)}`,
    },
    {
      title: 'Company',
      field: 'company',
      render: (rowData: any) => `${rowData?.company} `,
    },
    {
      title: 'Vehicle Type',
      field: 'vtype',
      render: (rowData: any) => `${rowData?.vtype} `,
    },
    {
      title: 'Own code',
      field: 'ocode',
      render: (rowData: any) => `${rowData?.ocode} `,
    },
    {
      title: 'Vehicle No',
      field: 'vno',
      render: (rowData: any) => `${rowData?.vno} `,
    },
    {
      title: 'Rep Veh',
      field: 'repveh',
      render: (rowData: any) => `${rowData?.repveh} `,
    },
    {
      title: 'Own code',
      field: 'rvocode',
      render: (rowData: any) => `${rowData?.rvocode} `,
    },
    {
      title: 'Particulars',
      render: (rowData: any) =>
        `${rowData.particulars} ${rowData?.particulars_type}`,
    },
    {
      title: 'Hire Type',
      field: 'hiretype',
      render: (rowData: any) => `${rowData?.hiretype} `,
    },
    {
      title: 'Sub agent',
      field: 'subagent',
      render: (rowData: any) => `${rowData?.subagent} `,
    },
    {
      title: 'Client',
      field: 'client',
      render: (rowData: any) => `${rowData?.client} `,
    },
    {
      title: 'Toll',
      field: 'toll',
      render: (rowData: any) => `${rowData?.toll} `,
    },
    {
      title: 'Bill No',
      field: 'billno',
      render: (rowData: any) => `${rowData?.billno} `,
    },
    {
      title: 'id',
      field: 'id',
      render: (rowData: any) => `${rowData?.id} `,
      hidden: true,
    },
  ];

  return (
    <Grid
      container
      direction="column"
      spacing={1}
      wrap="nowrap"
      sx={{
        height: '100%',
        padding: { xs: '0.5rem', sm: '1rem' },
      }}
    >
      <Grid item xs={2} sm={1} sx={{ paddingY: '1rem' }}>
        <PageTitle text="New Bill" />
      </Grid>

      <Grid item xs container spacing={2}>
        <Paper elevation={2} sx={{ width: '100%' }}>
          <Accordion style={{ padding: '10px' }}>
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon
                  sx={{
                    color: 'primary.main',
                    '&.Mui-expanded': { transform: 'rotate(180deg)' },
                  }}
                />
              }
              aria-controls="filter-content"
              id="filter-header"
              sx={{
                '& .MuiAccordionSummary-content': {
                  alignItems: 'center',
                  fontSize: '1.2rem',
                  fontWeight: 'bold',
                  color: 'primary.main',
                },
              }}
            >
              <Typography variant="h6">Filters</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <form onSubmit={handleSearch}>
                <Grid container spacing={2} style={{ padding: '10px' }}>
                  <Grid item xs={6} md={3}>
                    <Controller
                      control={control}
                      name="startDate"
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label="From Date"
                          variant="outlined"
                          size="medium"
                          fullWidth
                          sx={{ width: '100%' }}
                          InputLabelProps={{ shrink: true }}
                          type="date"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <Controller
                      control={control}
                      name="endDate"
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label="To Date"
                          variant="outlined"
                          size="medium"
                          fullWidth
                          sx={{ width: '100%' }}
                          InputLabelProps={{ shrink: true }}
                          type="date"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl
                      fullWidth
                      variant="outlined"
                      sx={{ width: '100%' }}
                    >
                      <InputLabel id="company-select-label">
                        Select Company
                      </InputLabel>
                      <Controller
                        control={control}
                        name="company"
                        render={({ field }) => (
                          <Select
                            {...field}
                            labelId="company-select-label"
                            label="Select Company"
                          >
                            {/* <MenuItem value="">
                              <em>Select Company</em>
                            </MenuItem> */}
                            {companies?.map((comp: any) => (
                              <MenuItem key={comp?.id} value={comp?.name}>
                                {comp?.name}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel htmlFor="searchBy">Search By</InputLabel>
                      <Controller
                        control={control}
                        name="searchBy"
                        render={({ field }) => (
                          <Select
                            {...field}
                            fullWidth
                            variant="outlined"
                            label="Search By"
                            inputProps={{
                              id: 'searchBy',
                            }}
                          >
                            <MenuItem value="">
                              <em>Select Search</em>
                            </MenuItem>
                            {SearchBy?.map((comp: any) => (
                              <MenuItem key={comp?.value} value={comp?.value}>
                                {comp?.label}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <Controller
                      control={control}
                      name="searchValue"
                      render={({ field }) => (
                        <TextField
                          {...field}
                          placeholder="Search Value"
                          variant="outlined"
                          size="medium"
                          fullWidth
                          sx={{ width: '100%' }}
                          InputLabelProps={{ shrink: false }}
                          type="text"
                        />
                      )}
                    />
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    md={6}
                    style={{ display: 'flex', flexWrap: 'wrap' }}
                  >
                    {['DEP', 'ARR', 'D/A', 'S/S', 'S/D', 'A/S', 'DISP'].map(
                      (option: any, index: number) => (
                        <Grid key={index}>
                          <FormControlLabel
                            control={
                              <Controller
                                name="hirechartType"
                                control={control}
                                render={({ field }) => (
                                  <Checkbox
                                    {...field}
                                    onChange={e =>
                                      field.onChange(
                                        e.target.checked
                                          ? [...field.value, option]
                                          : field.value.filter(
                                              item => item !== option
                                            )
                                      )
                                    }
                                    color="primary"
                                    value={option}
                                  />
                                )}
                              />
                            }
                            label={option}
                          />
                        </Grid>
                      )
                    )}
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleSearch}
                    >
                      Search
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </AccordionDetails>
          </Accordion>

          <Grid item xs={4} sm={2} md={2} lg={2} marginTop={2}>
            <Button variant="outlined" color="primary" onClick={handleMakeBill}>
              Make Bill
            </Button>
          </Grid>

          <Grid item xs={12} marginTop={1}>
            <MaterialTable
              title=""
              columns={columns}
              data={srlocalhirechart || []}
              isLoading={isLoading || isPending}
              options={{
                draggable: false,
                paging: false,
                search: true,
                searchFieldVariant: 'outlined',
                selection: true,
                tableLayout: 'fixed',
              }}
              style={{
                minHeight: 'calc(100vh - 180px)',
                overflowY: 'auto',
              }}
              components={{
                Container: props => <Paper elevation={0} {...props} />,
              }}
              // onRowClick={(_, rowData) => handleDetails(rowData)}
              onSelectionChange={rows => setSelectedRows(rows)}
            />
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default PendingBills;
