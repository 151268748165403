import { useState } from 'react';

import { Controller, useFormContext } from 'react-hook-form';

import { Autocomplete, Card, Grid, TextField, Typography } from '@mui/material';

import { useReadNCompanys } from '../../hooks/controlpanel/newcompanies';

function NewContracts() {
  const { control } = useFormContext();
  const { data: companyList = [] } = useReadNCompanys();

  const [selectedValue, setSelectedValue] = useState<any | null>(null);

  return (
    <div className="content">
      <div className="content-inner">
        <Card style={{ marginTop: '20px', padding: '10px' }}>
          <Typography variant="h5">New Contract</Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Controller
                name="date"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Date"
                    type="date"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    required
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} sx={{ marginTop: '1rem' }}>
              <Controller
                name="company"
                control={control}
                defaultValue={null}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    options={companyList}
                    getOptionLabel={option => option?.name || ''}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label="Company"
                        variant="outlined"
                        fullWidth
                      />
                    )}
                    onChange={(_, value) => {
                      field.onChange(value?.name || '');
                      setSelectedValue(value);
                    }}
                    value={selectedValue}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Card>
      </div>
    </div>
  );
}

export default NewContracts;
